import React, { useCallback } from 'react';
import {
  Avatar,
  Dialog,
  DialogContent,
  IconButton,
  Theme,
} from '@material-ui/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import { makeStyles } from '@material-ui/core/styles';
import {
  FlexRow,
  Image,
  Typography,
  View,
} from '@memoryfactory/mui-components';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShareIcon from '@material-ui/icons/Share';
import ArrowRightIcon from '@material-ui/icons/ChevronRight';
import ArrowLeftIcon from '@material-ui/icons/ChevronLeft';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Forward10Icon from '@material-ui/icons/Forward10';
import Replay10Icon from '@material-ui/icons/Replay10';
import clsx from 'clsx';

import {
  playerModalState,
  currentTrackState,
  playerTrackListState,
  playerPlayState,
  playerProgressState,
} from '~/features/works/recoil';
import useDisclose from '~/hooks/useDisclose';
import { BASE_URL } from '~/lib/constants';
import { useStoryLike } from '~/features/works/mutations';
import useLoginRequiredDialog from '~/hooks/useLoginRequiredDialog';

import PlayerSlider from '../PlayerSlider';
import ShareDialog from './ShareDialog';

const useStyles = makeStyles<Theme, { background?: string }>((theme) => ({
  root: {
    position: 'relative',
  },
  paper: {
    margin: 0,
    maxWidth: '100%',
    width: '100%',
  },
  content: {
    overflow: 'visible',
    position: 'relative',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '&:first-child': {
      paddingTop: 0,
    },
    '& .back-cover': {
      position: 'absolute',
      top: 0,
      left: 0,
      filter: 'blur(8px) brightness(0.9)',
      '-webkit-filter': 'blur(8px) brightness(0.9)',
    },
    '& .cover': {
      width: 200,
      height: 200,
      borderRadius: '20px',
      marginBottom: theme.spacing(3),
    },
    '& .control': {},
    '& .control, .control *': {
      zIndex: 1,
    },
  },
  icon: {
    color: 'white',
  },
  iconOpacity: {
    opacity: 0.6,
  },
  closeIcon: {
    alignSelf: 'flex-end',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    wordBreak: 'normal',
    overflow: 'hidden',
    opacity: 0.8,
  },
  seekIcon: {
    color: 'white',
    fontSize: 28,
  },
  playIcon: {
    color: 'white',
    fontSize: 42,
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
}));

type TProps = {
  onSeek: (value: number) => void;
};

const PlayerModal: React.FunctionComponent<TProps> = ({ onSeek }) => {
  const {
    isOpen: shareOpen,
    onClose: onShareClose,
    onOpen: onShareOpen,
  } = useDisclose();
  const history = useHistory();
  const [open, setOpen] = useRecoilState(playerModalState);
  const [currentTrack, setCurrentTrack] = useRecoilState(currentTrackState);
  const [playing, setPlaying] = useRecoilState(playerPlayState);
  const progress = useRecoilValue(playerProgressState);
  const playList = useRecoilValue(playerTrackListState);
  const likeMutation = useStoryLike(!!currentTrack?.track.isLiked);

  const classes = useStyles({ background: currentTrack?.track.artwork });

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const mutateLike = useCallback(() => {
    if (!likeMutation.isLoading && currentTrack?.track.id) {
      likeMutation.mutate(currentTrack.track.id, {
        onSuccess: () => {
          setCurrentTrack({
            index: currentTrack.index,
            track: {
              ...currentTrack.track,
              isLiked: !currentTrack.track.isLiked,
            },
          });
        },
      });
    }
  }, [currentTrack, likeMutation, setCurrentTrack]);

  const { renderDialog: renderLoginDialog, onSubmit: onLikePress } =
    useLoginRequiredDialog(mutateLike);

  const onTrackChangeClick = useCallback(
    (changeIndex: number) => {
      setCurrentTrack({ index: changeIndex, track: playList[changeIndex] });
    },
    [setCurrentTrack, playList],
  );

  const onPrevClick = useCallback(() => {
    if (!playList) return;

    const currentIndex = currentTrack?.index || 0;
    const nextIndex = currentIndex - 1;

    if (nextIndex >= 0) {
      onTrackChangeClick(nextIndex);
    }
  }, [currentTrack?.index, onTrackChangeClick, playList]);

  const onNextClick = useCallback(() => {
    if (!playList) return;

    const currentIndex = currentTrack?.index || 0;
    const nextIndex = currentIndex + 1;

    if (nextIndex <= playList.length - 1) {
      onTrackChangeClick(nextIndex);
    }
  }, [currentTrack?.index, onTrackChangeClick, playList]);

  const onPlayerSeek = useCallback(
    (e: any, value: number | number[]) => {
      if (currentTrack && typeof value === 'number') {
        onSeek(parseFloat(`${value}`));
      }
    },
    [currentTrack, onSeek],
  );

  const onSecondsClick = useCallback(
    (value: number) => {
      if (currentTrack) {
        const { playedSeconds } = progress;
        const nextSeconds = playedSeconds + value;
        const nextPlayed = nextSeconds / currentTrack.track.duration;
        if (nextSeconds > 0 && nextSeconds < currentTrack.track.duration) {
          onSeek(parseFloat(`${nextPlayed}`));
        }
      }
    },
    [onSeek, currentTrack, progress],
  );

  const onInfoClick = useCallback(() => {
    if (currentTrack) {
      const prefix =
        currentTrack.track.contentType === 'storyCollection'
          ? '/collections'
          : '/series';
      onClose();
      history.push(`${prefix}/${currentTrack.track.seriesId}`);
    }
  }, [currentTrack, history, onClose]);

  if (!currentTrack) return null;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        classes={{ container: classes.root, paper: classes.paper }}
      >
        <DialogContent classes={{ root: classes.content }}>
          <Image
            className="back-cover"
            src={currentTrack.track.artwork}
            alt={currentTrack.track.title}
            width="100%"
            height="100%"
          />
          <View className="control" justifyContent="center">
            <IconButton onClick={onClose} className={classes.closeIcon}>
              <ExpandMoreIcon fontSize="large" className={classes.icon} />
            </IconButton>
            <FlexRow justifyContent="space-between" mb={1} position="relative">
              <View>
                <IconButton onClick={onShareOpen}>
                  <ShareIcon
                    fontSize="small"
                    className={clsx(classes.icon, classes.iconOpacity)}
                  />
                </IconButton>
              </View>
              <FlexRow>
                <IconButton onClick={onInfoClick}>
                  <InfoIcon
                    className={clsx(classes.icon, classes.iconOpacity)}
                  />
                </IconButton>
                <IconButton onClick={onLikePress}>
                  {currentTrack.track.isLiked ? (
                    <FavoriteIcon
                      fontSize="small"
                      className={clsx(classes.icon, classes.iconOpacity)}
                    />
                  ) : (
                    <FavoriteBorderIcon
                      fontSize="small"
                      className={clsx(classes.icon, classes.iconOpacity)}
                    />
                  )}
                </IconButton>
              </FlexRow>
            </FlexRow>
            <View alignSelf="center" alignItems="center">
              <Image
                className="cover"
                src={currentTrack.track.artwork}
                alt={currentTrack.track.title}
                width={200}
                height={200}
              />
              <FlexRow alignItems="center" mb={1}>
                <Avatar
                  alt="작가"
                  className={classes.avatar}
                  src={currentTrack.track.artistCover}
                  component="div"
                />
                <Typography white variant="body2" style={{ opacity: 0.6 }}>
                  {currentTrack.track.artist}
                </Typography>
              </FlexRow>
              <Typography variant="h6" white>
                {currentTrack.track.seriesName}
              </Typography>
            </View>
            <FlexRow justifyContent="space-between" px={1}>
              <IconButton onClick={onPrevClick}>
                <ArrowLeftIcon
                  fontSize="large"
                  className={clsx(classes.icon, classes.iconOpacity)}
                />
              </IconButton>
              <View alignItems="center">
                <Typography
                  white
                  variant="body2"
                  gutterBottom
                  className={classes.title}
                >
                  {currentTrack.track.title}
                </Typography>
              </View>
              <IconButton onClick={onNextClick}>
                <ArrowRightIcon
                  fontSize="large"
                  className={clsx(classes.icon, classes.iconOpacity)}
                />
              </IconButton>
            </FlexRow>
            {/* Progress */}
            <View px={2}>
              <PlayerSlider
                value={progress.played}
                min={0}
                max={1}
                step={0.0001}
                onChange={onPlayerSeek}
              />
            </View>
            <FlexRow justifyContent="space-between" mb={2}>
              <Typography variant="caption" white>
                {new Date(progress.playedSeconds * 1000)
                  .toISOString()
                  .substr(14, 5)}
              </Typography>
              <Typography variant="caption" white>
                {new Date((currentTrack.track.duration || 0) * 1000)
                  .toISOString()
                  .substr(14, 5)}
              </Typography>
            </FlexRow>
            <FlexRow justifyContent="space-between" px={4}>
              <IconButton onClick={() => onSecondsClick(-10)}>
                <Replay10Icon className={classes.seekIcon} />
              </IconButton>
              <IconButton
                onClick={
                  playing ? () => setPlaying(false) : () => setPlaying(true)
                }
              >
                {playing ? (
                  <PauseIcon className={classes.playIcon} />
                ) : (
                  <PlayArrowIcon className={classes.playIcon} />
                )}
              </IconButton>
              <IconButton onClick={() => onSecondsClick(10)}>
                <Forward10Icon className={classes.seekIcon} />
              </IconButton>
            </FlexRow>
          </View>
        </DialogContent>
      </Dialog>
      <ShareDialog
        open={shareOpen}
        onClose={onShareClose}
        cover={currentTrack.track.artwork}
        url={`${BASE_URL}/${
          currentTrack.track.contentType === 'storyCollection'
            ? 'collections'
            : 'series'
        }/${currentTrack.track.seriesId}`}
      />
      {renderLoginDialog()}
    </>
  );
};

export default PlayerModal;
