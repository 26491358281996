import openColor from 'open-color';
import { createTheme } from '@material-ui/core/styles';

const defaultFontFamily =
  'Noto Serif KR,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,sans-serif';

// Create a theme instance.
export const muiTheme = createTheme({
  typography: {
    fontFamily: defaultFontFamily,
    fontSize: 16,
    h1: {
      fontSize: '6rem',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    h2: {
      fontSize: '3.75rem',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    h3: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    h4: {
      fontSize: '2.125rem',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 'normal',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 'normal',
    },
    body2: {
      fontSize: '.875rem',
      lineHeight: 'normal',
    },
    subtitle1: {
      fontWeight: 500,
      lineHeight: 'normal',
    },
    subtitle2: {
      lineHeight: 'normal',
    },
    caption: {
      fontSize: '.75rem',
      lineHeight: 'normal',
    },
    overline: {
      fontSize: '.625rem',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
  },
  palette: {
    primary: {
      main: '#A3525B',
      light: '#4383ff',
    },
    secondary: {
      main: '#f0ebe1',
    },
    text: {
      secondary: '#91949D',
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        fontFamily: defaultFontFamily,
        '&.Mui-disabled': {
          backgroundColor: openColor.gray[2],
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: 0,
        },
      },
    },
  },
});

export const styledTheme = {
  ...muiTheme,
};
