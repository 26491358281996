import { useCallback, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { TProgress } from '~/features/works/interfaces';
import {
  playerPlayState,
  currentTrackState,
  playerTrackListState,
  playerModalState,
  playerProgressState,
  bottomPlayerOpenState,
} from '~/features/works/recoil';

type TSetupTrackOption = {
  playImmediate?: boolean;
  openPlayer?: boolean;
  resetTrack?: boolean;
};

function usePlayer() {
  const [current, setCurrentTrack] = useRecoilState(currentTrackState);
  const [playList, setPlayList] = useRecoilState(playerTrackListState);
  const setPlaying = useSetRecoilState(playerPlayState);
  const setProgress = useSetRecoilState(playerProgressState);
  const setPlayerModal = useSetRecoilState(playerModalState);
  const setBottomPlayerModal = useSetRecoilState(bottomPlayerOpenState);

  const onPlayerEnded = useCallback(() => {
    if (current && current.index !== playList.length - 1) {
      setCurrentTrack({
        index: current.index + 1,
        track: playList[current.index + 1],
      });
    }
  }, [current, playList, setCurrentTrack]);

  const onPlayerProgress = useCallback(
    ({ playedSeconds, played }: TProgress) => {
      setProgress({ played, playedSeconds });
    },
    [setProgress],
  );

  const setupTrack = useCallback(
    (tracks: ITrack[], options?: TSetupTrackOption) => {
      const { playImmediate, openPlayer, resetTrack } = options || {
        playImmediate: true,
        openPlayer: true,
        resetTrack: true,
      };
      if (!playList.length) {
        // New List
        setBottomPlayerModal(true);
      }
      setPlayList(tracks);
      if (playImmediate) {
        setPlaying(true);
      }
      if (openPlayer) {
        setPlayerModal(true);
      }
      if (resetTrack) {
        setCurrentTrack({ index: 0, track: tracks[0] });
      }
      setBottomPlayerModal(true);
    },
    [
      playList.length,
      setCurrentTrack,
      setBottomPlayerModal,
      setPlayList,
      setPlayerModal,
      setPlaying,
    ],
  );

  const addTrack = useCallback(
    (track: ITrack) => {
      setPlayList([track, ...playList]);
      setTimeout(() => {
        setCurrentTrack({
          index: 0,
          track,
        });
        setPlaying(true);
        setPlayerModal(true);
        setBottomPlayerModal(true);
      }, 0);
    },
    [
      // playList,
      setPlayList,
      setCurrentTrack,
      setPlaying,
      setBottomPlayerModal,
      setPlayerModal,
    ],
  );

  useEffect(() => {
    if (playList.length && !current) {
      const newFirstTrack = playList[0];
      setCurrentTrack({ index: 0, track: newFirstTrack });
    }
  }, [current, playList, setCurrentTrack]);

  useEffect(() => {
    if (current) {
      setProgress({
        played: 0,
        playedSeconds: 0,
      });
    }
  }, [current, setProgress]);

  return {
    setupTrack,
    addTrack,
    onPlayerProgress,
    onPlayerEnded,
  };
}

export default usePlayer;
