import React, { Suspense, lazy, useState, useRef, useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AbsoluteCenterSpinner } from '@memoryfactory/mui-components';
import ReactPlayer from 'react-player/lazy';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import RequiredProfile from './components/RequiredProfile';
import BottomPlayer from './components/BottomPlayer';
import usePlayer from './hooks/usePlayer';
import PlayListModal from './components/modals/PlayListModal';
import PlayerModal from './components/modals/PlayerModal';
import InitialVisitModal from './components/modals/InitialVisitModal';

import {
  currentTrackState,
  playerPlayState,
  playerProgressState,
} from './features/works/recoil';

const RecommendSeriesScreen = lazy(
  () => import('./pages/RecommendSeriesScreen'),
);
const DiscoverSeriesScreen = lazy(() => import('./pages/DiscoverSeriesScreen'));
const AudioClass = lazy(() => import('./pages/AudioClass'));

const StorySeriesScreen = lazy(() => import('./pages/StorySeriesScreen'));
const StoryCollectionScreen = lazy(
  () => import('./pages/StoryCollectionScreen'),
);
const PublicTracking = lazy(() => import('./pages/PublicTracking'));
const SearchAllScreen = lazy(() => import('./pages/SearchAllScreen'));
const AuthorProfileScreen = lazy(() => import('./pages/AuthorProfileScreen'));
const EmailSignupScreen = lazy(() => import('./pages/auth/EmailSignupScreen'));
const EmailLoginScreen = lazy(() => import('./pages/auth/EmailLoginScreen'));
const LoginScreen = lazy(() => import('./pages/auth/LoginScreen'));
const ProfileCreateScreen = lazy(
  () => import('./pages/auth/ProfileCreateScreen'),
);
const PhoneVerificationScreen = lazy(
  () => import('./pages/auth/PhoneVerificationScreen'),
);
const TopMenuScreen = lazy(() => import('./pages/TopMenuScreen'));
const ProfileSettingScreen = lazy(
  () => import('./pages/auth/ProfileSettingScreen'),
);
const ProfileDetailScreen = lazy(
  () => import('./pages/auth/ProfileDetailScreen'),
);
const RequestResetPasswordSceen = lazy(
  () => import('./pages/auth/RequestResetPasswordSceen'),
);
const ResetPasswordScreen = lazy(
  () => import('./pages/auth/ResetPasswordScreen'),
);
const SubscribedUserListScreen = lazy(
  () => import('./pages/SubscribedUserListScreen'),
);
const SettingScreen = lazy(() => import('./pages/auth/SettingScreen'));

function App() {
  const player = useRef<ReactPlayer>(null);
  const { onPlayerEnded, onPlayerProgress } = usePlayer();
  const playing = useRecoilValue(playerPlayState);
  const currentTrack = useRecoilValue(currentTrackState);
  const setProgress = useSetRecoilState(playerProgressState);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 2,
            retryDelay: (attempt) =>
              Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
          },
        },
      }),
  );

  const onPlayerSeek = useCallback(
    (value: number) => {
      if (player?.current && currentTrack) {
        player.current.seekTo(value);
        setProgress({
          played: value,
          playedSeconds: currentTrack.track.duration * value,
        });
      }
    },
    [currentTrack, setProgress],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <Suspense fallback={<AbsoluteCenterSpinner />}>
          <Switch>
            <Route exact path="/" component={DiscoverSeriesScreen} />
            <Route path="/recomendation" component={RecommendSeriesScreen} />
            <Route path="/class" component={AudioClass} />
            <Route
              path="/accounts/reset-password/:uid/:token"
              component={ResetPasswordScreen}
            />
            <Route
              path="/accounts/reset-password"
              component={RequestResetPasswordSceen}
            />
            <Route
              path="/accounts/verifications/phone"
              component={PhoneVerificationScreen}
            />
            <Route path="/accounts/profiles" component={ProfileCreateScreen} />
            <Route path="/accounts/signup" component={EmailSignupScreen} />
            <Route path="/accounts/login/email" component={EmailLoginScreen} />
            <Route path="/accounts/login" component={LoginScreen} />
            <Route path="/collections/:id" component={StoryCollectionScreen} />
            <Route path="/series/:id" component={StorySeriesScreen} />
            <Route
              path="/authors/:id/subscribers"
              component={SubscribedUserListScreen}
            />
            <Route path="/authors/:id" component={AuthorProfileScreen} />
            <Route path="/search" component={SearchAllScreen} />
            <Route path="/profiles/detail" component={ProfileDetailScreen} />
            <Route path="/profiles/me" component={ProfileSettingScreen} />
            <Route path="/profiles" component={TopMenuScreen} />
            <Route path="/settings" component={SettingScreen} />
            <Route path="/test" component={PublicTracking} />
          </Switch>
        </Suspense>
        <RequiredProfile />
        <ReactPlayer
          ref={player}
          url={currentTrack?.track?.url}
          playing={playing}
          width={0}
          height={0}
          onProgress={onPlayerProgress}
          onEnded={onPlayerEnded}
        />
        <PlayListModal />
        <PlayerModal onSeek={onPlayerSeek} />
        <BottomPlayer />
        <InitialVisitModal />
      </div>
    </QueryClientProvider>
  );
}

export default App;
