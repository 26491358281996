import qs from 'query-string';
import restClient from '~/utils/client';
import { ICommentListItem } from '~/features/profiles/interfaces';

import {
  ICommentListParam,
  IStoryCollectionItem,
  IStoryCollectionListItem,
  IStoryCollectionListParam,
  IStoryListItem,
  IStoryListParam,
  IStorySeriesItem,
  IStorySeriesListItem,
  IStorySeriesListParam,
} from './interfaces';

const PREFIX = '/works';

export function getStoryList(params?: IStoryListParam) {
  return restClient
    .get<IListResponse<IStoryListItem>>(
      `${PREFIX}/stories/?${qs.stringify({ ...params })}`,
    )
    .then((res) => res?.data?.results);
}

export function getStorySeriesList(params?: IStorySeriesListParam) {
  return restClient
    .get<IListResponse<IStorySeriesListItem>>(
      `${PREFIX}/series/?${qs.stringify({ ...params })}`,
    )
    .then((res) => res?.data);
}

export function getStorySeries(id: number) {
  return restClient
    .get<IStorySeriesItem>(`${PREFIX}/series/${id}/`)
    .then((res) => res?.data);
}

export function getStoryCollectionList(params?: IStoryCollectionListParam) {
  return restClient
    .get<IListResponse<IStoryCollectionListItem>>(
      `${PREFIX}/collections/?${qs.stringify({ ...params })}`,
    )
    .then((res) => res?.data?.results);
}

export function getStoryCollection(id: number) {
  return restClient
    .get<IStoryCollectionItem>(`${PREFIX}/collections/${id}/`)
    .then((res) => res?.data);
}

export function getLikedStorySeriesList(params?: IStoryListParam) {
  return restClient
    .get<IListResponse<IStorySeriesListItem & { series: number }>>(
      `${PREFIX}/series/likes/?${qs.stringify({ ...params })}`,
    )
    .then((res) => res?.data);
}

export function storyCollectionLikeMutation(id: number) {
  return restClient.post(`${PREFIX}/collections/${id}/likes/`, {
    collection: id,
  });
}

export function storyCollectionUnlikeMutation(id: number) {
  return restClient.delete(`${PREFIX}/collections/${id}/likes/`);
}

export function storyLikeMutation(id: number) {
  return restClient.post(`${PREFIX}/stories/${id}/likes/`, { story: id });
}

export function storyUnlikeMutation(id: number) {
  return restClient.delete(`${PREFIX}/stories/${id}/likes/`);
}

export function storySeriesLikeMutation(id: number) {
  return restClient.post(`${PREFIX}/series/${id}/likes/`, { series: id });
}

export function storySeriesUnlikeMutation(id: number) {
  return restClient.delete(`${PREFIX}/series/${id}/likes/`);
}

export function createStoryComment(values: ICommentData) {
  return restClient.post(`${PREFIX}/stories/${values.id}/comments/`, {
    story: values.id,
    ...values.data,
  });
}

export function createStorySeriesComment(values: ICommentData) {
  return restClient.post(`${PREFIX}/series/${values.id}/comments/`, {
    series: values.id,
    ...values.data,
  });
}

export function createStoryCollectionComment(values: ICommentData) {
  return restClient.post(`${PREFIX}/collections/${values.id}/comments/`, {
    collection: values.id,
    ...values.data,
  });
}

export function getStoryCommentList(id: number, params?: ICommentListParam) {
  return restClient
    .get<IListResponse<ICommentListItem>>(
      `${PREFIX}/stories/${id}/comments/?${qs.stringify({ ...params })}`,
    )
    .then((res) => res?.data);
}

export function getStorySeriesCommentList(
  id: number,
  params?: ICommentListParam,
) {
  return restClient
    .get<IListResponse<ICommentListItem>>(
      `${PREFIX}/series/${id}/comments/?${qs.stringify({ ...params })}`,
    )
    .then((res) => res?.data);
}

export function getStoryCollectionCommentList(
  id: number,
  params?: ICommentListParam,
) {
  return restClient
    .get<IListResponse<ICommentListItem>>(
      `${PREFIX}/collections/${id}/comments/?${qs.stringify({ ...params })}`,
    )
    .then((res) => res?.data);
}
