import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useUser from '~/hooks/useUser';

function RequiredProfile() {
  const user = useUser({});
  const history = useHistory();

  useEffect(() => {
    if (user) {
      if (!user.hasProfile) {
        history.replace('/accounts/profiles');
      }
      if (!user.hasVerification) {
        history.replace('/accounts/verifications/phone');
      }
    }
  }, [history, user]);

  return null;
}

export default RequiredProfile;
