/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import { useInfiniteQuery, useQuery } from 'react-query';
import { getAuthorCommentList } from '../profiles/featchers';
import {
  getStorySeries,
  getStoryCollectionList,
  getStorySeriesList,
  getStoryCollection,
  getStoryCommentList,
  getStorySeriesCommentList,
  getStoryCollectionCommentList,
  getStoryList,
  getLikedStorySeriesList,
} from './fetchers';
import {
  IStoryCollectionListParam,
  IStoryListParam,
  IStorySeriesListParam,
} from './interfaces';

export function useStoryListQuery(params?: IStoryListParam, enabled = true) {
  return useQuery(['storyList', params], () => getStoryList(params), {
    enabled,
  });
}

export function useStorySeriesListQuery(
  params?: IStorySeriesListParam,
  enabled = true,
) {
  return useQuery(
    ['storySeriesList', params],
    () => getStorySeriesList(params),
    { enabled },
  );
}

export function useStorySeriesListInfiniteQuery(
  params?: IStorySeriesListParam,
) {
  return useInfiniteQuery(
    ['storySeriesInfinteList', params],
    ({ pageParam }) => getStorySeriesList({ ...params, page: pageParam }),
    {
      getNextPageParam: (lp, pages) =>
        lp?.next ? pages.length + 1 : undefined,
      staleTime: 1000 * 60 * 5,
    },
  );
}

export function useStorySeriesQuery(id: number) {
  return useQuery(['storySeries', id], () => getStorySeries(id));
}

export function useStoryCollectionListQuery(
  params?: IStoryCollectionListParam,
) {
  return useQuery(['storyCollectionList', params], () =>
    getStoryCollectionList(params),
  );
}

export function useStoryCollectionQuery(id: number) {
  return useQuery(['storyCollection', id], () => getStoryCollection(id));
}

export function useContentCommentListQuery(type: TCommentType, id: number) {
  const fetcher =
    type === 'story'
      ? getStoryCommentList
      : type === 'storySeries'
      ? getStorySeriesCommentList
      : type === 'author'
      ? getAuthorCommentList
      : getStoryCollectionCommentList;
  return useInfiniteQuery(
    [`${type}CommentList`, id],
    ({ pageParam }) => fetcher(id, { page: pageParam }),
    {
      getNextPageParam: (lp, pages) =>
        lp?.next ? pages.length + 1 : undefined,
      staleTime: 1000 * 60 * 5,
    },
  );
}

export function useLikedStorySeriesListQuery(params?: IStoryListParam) {
  return useInfiniteQuery(
    ['likedStorySeriesList', params],
    ({ pageParam }) => getLikedStorySeriesList({ page: pageParam }),
    {
      getNextPageParam: (lp, pages) =>
        lp?.next ? pages.length + 1 : undefined,
      staleTime: 1000 * 60 * 5,
    },
  );
}
