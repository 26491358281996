import React from 'react';
import ReactDOM, { hydrate, render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { StylesProvider, MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { RecoilRoot } from 'recoil';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import { muiTheme, styledTheme } from '~/lib/styles/theme';
import 'reset-css';
import '~/lib/styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { render } from '@testing-library/react';

const client = new ApolloClient({
  // uri: 'http://localhost:3000',
  uri: 'https://api.nadio.shop/',
  cache: new InMemoryCache(),
});
const rootElement = document.getElementById('root');
if (rootElement?.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Router>
          <RecoilRoot>
            <StylesProvider injectFirst>
              <StyledThemeProvider theme={styledTheme}>
                <MuiThemeProvider theme={muiTheme}>
                  <App />
                </MuiThemeProvider>
              </StyledThemeProvider>
            </StylesProvider>
          </RecoilRoot>
        </Router>
      </ApolloProvider>
    </React.StrictMode>,
    rootElement,
  );
} else {
  render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Router>
          <RecoilRoot>
            <StylesProvider injectFirst>
              <StyledThemeProvider theme={styledTheme}>
                <MuiThemeProvider theme={muiTheme}>
                  <App />
                </MuiThemeProvider>
              </StyledThemeProvider>
            </StylesProvider>
          </RecoilRoot>
        </Router>
      </ApolloProvider>
    </React.StrictMode>,
    rootElement,
  );
}
// ReactDOM.render(
//   <React.StrictMode>
//     <ApolloProvider client={client}>
//       <Router>
//         <RecoilRoot>
//           <StylesProvider injectFirst>
//             <StyledThemeProvider theme={styledTheme}>
//               <MuiThemeProvider theme={muiTheme}>
//                 <App />
//               </MuiThemeProvider>
//             </StyledThemeProvider>
//           </StylesProvider>
//         </RecoilRoot>
//       </Router>
//     </ApolloProvider>
//   </React.StrictMode>,
//   document.getElementById('root'),
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
