import React, { useMemo, useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import {
  FlexRow,
  Image,
  Typography,
  View,
} from '@memoryfactory/mui-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CloseIcon from '@material-ui/icons/Close';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import {
  bottomPlayerOpenState,
  currentTrackState,
  playerPlayState,
  playListModalState,
} from '~/features/works/recoil';

const useStyles = makeStyles((theme) => ({
  root: {
    opacity: 0.9,
    position: 'fixed',
    bottom: theme.spacing(2),
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  cover: {
    marginRight: theme.spacing(2),
  },
  icon: {
    fontSize: 32,
    color: theme.palette.grey['500'],
  },
  title: {
    textOverflow: 'ellipsis',
    wordBreak: 'keep-all',
    overflow: 'hidden',
    display: '-webkit-box',
    lineHeight: '1.4em',
    height: '1.4em',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
}));

const excludeRoutes = ['/accounts', '/agreements', '/profiles', '/settings'];

function BottomPlayer() {
  const classes = useStyles();
  const theme = useTheme();

  const [playing, setPlaying] = useRecoilState(playerPlayState);
  const [open, setOpen] = useRecoilState(bottomPlayerOpenState);
  const setPlayListModal = useSetRecoilState(playListModalState);
  const current = useRecoilValue(currentTrackState);

  const location = useLocation();
  const currentPath = useMemo(() => location.pathname, [location]);

  const onClose = useCallback(() => {
    setPlaying(false);
    setOpen(false);
  }, [setPlaying, setOpen]);

  if (
    !open ||
    !current ||
    excludeRoutes.some((r) => currentPath.startsWith(r))
  ) {
    return null;
  }

  return (
    <FlexRow
      className={classes.root}
      bgcolor="white"
      justifyContent="space-between"
      boxShadow={6}
      maxWidth={theme.breakpoints.values.sm - theme.spacing(4)}
    >
      <FlexRow
        pointer
        flex={1}
        height={75}
        onClick={() => setPlayListModal(true)}
      >
        <Image
          className={classes.cover}
          width={75}
          height="100%"
          src={current.track.artwork}
          alt={current.track.title}
        />
        <View>
          <Typography variant="subtitle1" className={classes.title}>
            {current.track.title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.title}
          >
            {current.track.seriesName}
          </Typography>
        </View>
      </FlexRow>
      <FlexRow>
        <IconButton
          onClick={playing ? () => setPlaying(false) : () => setPlaying(true)}
        >
          {playing ? (
            <PauseIcon fontSize="large" className={classes.icon} />
          ) : (
            <PlayArrowIcon fontSize="large" className={classes.icon} />
          )}
        </IconButton>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="large" className={classes.icon} />
        </IconButton>
      </FlexRow>
    </FlexRow>
  );
}

export default BottomPlayer;
