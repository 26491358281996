import { Image, View } from '@memoryfactory/mui-components';

interface Props {
  size?: number;
  round?: boolean;
}

function KakaoIcon({ size = 48, round }: Props) {
  return (
    <View
      p={1}
      bgcolor="#FFE600"
      borderRadius={round ? '50%' : 0}
      width={size}
      height={size}
    >
      <Image
        src="/images/kakao.png"
        alt="kakaotalk image"
        width="100%"
        height="100%"
        objectFit="fill"
      />
    </View>
  );
}

export default KakaoIcon;
