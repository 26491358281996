import { atom } from 'recoil';
import { ICommentParamState, TProgress } from './interfaces';

export const commentParamState = atom<ICommentParamState>({
  key: 'works/commentParamState',
  default: {
    id: 0,
    type: 'story',
  },
});

export const commentModalState = atom<boolean>({
  key: 'works/commentModalState',
  default: false,
});

export const playListModalState = atom<boolean>({
  key: 'works/playListModalState',
  default: false,
});

export const bottomPlayerOpenState = atom<boolean>({
  key: 'works/bottomPlayerOpenState',
  default: true,
});

export const playerTrackListState = atom<ITrack[]>({
  key: 'works/playerTrackListState',
  default: [],
});

export const currentTrackState = atom<{
  index: number;
  track: ITrack;
} | null>({
  key: 'works/currentTrackState',
  default: null,
});

export const playerPlayState = atom<boolean>({
  key: 'works/playerPlayState',
  default: false,
});

export const playerProgressState = atom<
  Pick<TProgress, 'played' | 'playedSeconds'>
>({
  key: 'works/playerProgressState',
  default: {
    played: 0,
    playedSeconds: 0,
  },
});

export const playerModalState = atom<boolean>({
  key: 'works/playerModalState',
  default: false,
});

export const randomIndex = atom<number>({
  key: 'randomIndex',
  default: Math.floor(Math.random() * 3),
});
export const otherData = atom({
  key: 'otherData',
  default: {
    items: [
      {
        author: {
          authorId: 3,
          audio: 'nadio_T_경인미타.mp4',
          desc: '숨, 쉼, 그리고 소리',
          authorName: '경인미타',
          linkTo: 24,
          image: '/nadio_T_함경인.png',
        },
      },
      {
        author: {
          authorId: 23,
          audio: 'nadio_T_강예빈.mp4',
          desc: '게으른 나도 사랑해주세요',
          authorName: '강예빈',
          linkTo: 25,
          image: 'nadio_T_강예빈.png',
        },
      },
      {
        author: {
          authorId: 99,
          audio: 'nadio_T_향기.mp4',
          desc: '약함의 미학',
          authorName: '향기',
          linkTo: 111,
          image: 'nadio_T_향기.png',
        },
      },
    ],
  },
});
