import React, { useState, useEffect } from 'react';
import { Button, Modal, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { View, Image } from '@memoryfactory/mui-components';
import CloseIcon from '@material-ui/icons/Close';
import { isBrowser, isAndroid, isIOS } from 'react-device-detect';

const style = makeStyles({
  box: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: `${isBrowser ? '25.3rem' : '80%'}`,
    height: `${isBrowser ? '37rem' : ''}`,
    borderRadius: 10,
    outline: 'none',
  },
  button: {
    width: '65%',
    height: 48,
    backgroundColor: '#b14c59',
    marginTop: 15,
    borderRadius: 20,
    color: '#FFFFFF',
  },
  icon: {
    color: 'grey',
  },
  span: {
    color: '#898989',
    fontSize: 15.5,
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: '#efefef',
    borderRadius: 30,
    width: 150,
  },
  noPopUpText: {
    color: '#a3a3a3',
    fontSize: 12,
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 10,
    textDecoration: 'underline',
  },
  image: {
    marginTop: 18,
  },
});

const redirectBasedOnOS = () => {
  if (isIOS) {
    window.location.href =
      'https://apps.apple.com/us/app/%EB%82%98%EB%94%94%EC%98%A4-%EB%82%98%EB%A5%BC-%EC%9C%84%ED%95%9C-%EC%98%A4%EB%94%94%EC%98%A4/id1588026115';
  } else if (isAndroid) {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.nadio';
  } else {
    console.error('something went wrong');
  }
};
const InitialVisitModal: React.FunctionComponent = () => {
  const [open, setOpen] = useState<boolean>(false);
  const HAS_VISITED_BEFORE = localStorage.getItem('hasVisitedBefore');

  useEffect(() => {
    const handleShowModal = () => {
      if (HAS_VISITED_BEFORE && (HAS_VISITED_BEFORE as any) > new Date()) {
        return;
      }
      if (!HAS_VISITED_BEFORE) {
        setOpen(true);
        let expires: any = new Date();
        expires = expires.setHours(expires.getHours() + 72);
        localStorage.setItem('hasVisitedBefore', expires);
      }
    };
    window.setTimeout(handleShowModal, 1000);
  }, [HAS_VISITED_BEFORE]);

  const handleClose = () => {
    setOpen(false);
  };
  const classes = style();
  return (
    <div>
      <Modal open={open}>
        <Box className={classes.box} sx={{ bgcolor: '#f6f1eb' }}>
          <View
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <View
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <CloseIcon
                fontSize="large"
                onClick={() => {
                  setOpen(false);
                  localStorage.removeItem('hasVisitedBefore');
                }}
                className={classes.icon}
              />
            </View>
            <Image width={330} src="/slice-1@2x.png" alt="modal-image" />
            <Button
              className={classes.button}
              onClick={() =>
                (window.location.href = 'https://nadio.io/contest')
              }
            >
              공모전 참여하기
            </Button>
            <Button onClick={handleClose} className={classes.noPopUpText}>
              {' '}
              3일 동안 보지않기
            </Button>{' '}
          </View>
        </Box>
      </Modal>
    </div>
  );
};

export default InitialVisitModal;
