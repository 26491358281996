import React, { useState, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import {
  View,
  FlexRow,
  Typography,
  TextInput,
} from '@memoryfactory/mui-components';
import { CircularProgress, Avatar, Button } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';

import { ICommentListItem } from '~/features/profiles/interfaces';
import { useContentCommentMutation } from '~/features/works/mutations';
import useUser from '~/hooks/useUser';

type TProps = {
  item: ICommentListItem;
  type: TCommentType;
  contentId: number;
  user: ReturnType<typeof useUser>;
};

type TFormValues = {
  text: string;
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  replyIcon: {
    fontSize: 16,
    cursor: 'pointer',
    color: theme.palette.grey['300'],
  },
  input: {
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: 'white',
  },
}));

const CommentRow: React.FunctionComponent<TProps> = ({
  item,
  type,
  contentId,
  user,
}) => {
  const classes = useStyles();

  const [openReply, setOpenReply] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting, errors: formErrors },
  } = useForm<TFormValues>();

  const submitAble = !!(watch('text') as string)?.trim();

  const commentMutation = useContentCommentMutation(type, contentId);

  const onSubmit: SubmitHandler<TFormValues> = useCallback(
    (values) => {
      if (!commentMutation.isLoading) {
        const data = { ...values, parent: item.id };
        commentMutation.mutate(
          { id: contentId, data },
          {
            onSuccess: () => {
              reset({ text: '' });
            },
          },
        );
      }
    },
    [commentMutation, item, contentId, reset],
  );

  return (
    <View position="relative" mb={2}>
      {commentMutation.isLoading && (
        <View
          position="absolute"
          top={0}
          bottom={0}
          right={0}
          left={0}
          zIndex={100}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </View>
      )}
      <FlexRow mb={2} alignItems="center" justifyContent="space-between">
        <FlexRow>
          <Avatar
            className={classes.avatar}
            src={item.user.image}
            component="div"
          />
          <Typography variant="body2" bold>
            {item.user.name}
          </Typography>
        </FlexRow>
        <Typography variant="caption" style={{ color: '#91949d' }}>
          {dayjs(item.createdAt).format('YYYY.MM.DD')}
        </Typography>
      </FlexRow>
      <View mb={1} pl={5}>
        <Typography variant="body2">{item.text}</Typography>
      </View>
      <View pl={5}>
        {user && (
          <CommentIcon
            className={classes.replyIcon}
            onClick={() => setOpenReply((v) => !v)}
          />
        )}
      </View>
      {item.children.map(({ id, text, user: { name }, createdAt }) => (
        <FlexRow
          key={`Reply${id}`}
          pl={8}
          mb={2}
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <FlexRow alignItems="flex-end" flexWrap="wrap">
            <Typography gutterBottom>{name}</Typography>
            <View width="100%">
              <Typography variant="body2">{text}</Typography>
            </View>
          </FlexRow>
          <Typography variant="caption">
            {dayjs(createdAt).format('YYYY.MM.DD')}
          </Typography>
        </FlexRow>
      ))}
      {openReply && (
        <TextInput
          name="text"
          errors={formErrors}
          register={register({
            required: '답글을 입력해주세요',
          })}
          placeholder="답글을 남겨주세요"
          InputProps={{
            classes: { input: classes.input },
            endAdornment: (
              <Button
                color="primary"
                variant="contained"
                size="small"
                disabled={!submitAble || isSubmitting}
                onClick={handleSubmit(onSubmit)}
              >
                작성
              </Button>
            ),
          }}
        />
      )}
    </View>
  );
};

export default React.memo(CommentRow);
