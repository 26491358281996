import React, { useCallback } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PersonIcon from '@material-ui/icons/Person';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { Typography } from '@memoryfactory/mui-components';
import { makeStyles } from '@material-ui/core/styles';

import { useStoryLike } from '~/features/works/mutations';
import useLoginRequiredDialog from '~/hooks/useLoginRequiredDialog';
import useDisclose from '~/hooks/useDisclose';

import {
  commentModalState,
  commentParamState,
  playListModalState,
} from '~/features/works/recoil';
import { IStoryParent } from '~/features/works/interfaces';

import Dialog from './Dialog';

type TProps = {
  open: boolean;
  onClose: () => void;
  isLiked: boolean;
  storyId: number;
  parent: IStoryParent;
  authorId: number;
  script: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.sm,
    margin: '0 auto',
  },
  text: {
    fontWeight: 700,
  },
  script: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    wordBreak: 'keep-all',
  },
  scriptDialog: {
    minWidth: 340,
  },
}));

const StoryActionSheet: React.FunctionComponent<TProps> = ({
  open,
  onClose,
  isLiked,
  storyId,
  parent,
  authorId,
  script,
}) => {
  const {
    isOpen: isDialogOpen,
    onClose: onDialogClose,
    onOpen: onDialogOpen,
  } = useDisclose();
  const history = useHistory();
  const classes = useStyles();

  const setCommentParam = useSetRecoilState(commentParamState);
  const setOpen = useSetRecoilState(commentModalState);
  const setPlayListModal = useSetRecoilState(playListModalState);

  const likeMutation = useStoryLike(isLiked);
  const mutateLike = useCallback(() => {
    if (!likeMutation.isLoading) {
      likeMutation.mutate(storyId);
    }
  }, [storyId, likeMutation]);

  const { renderDialog: renderLoginDialog, onSubmit: onLikePress } =
    useLoginRequiredDialog(mutateLike);

  const onAuthorPress = () => {
    onClose();
    setPlayListModal(false);
    history.push(`/authors/${authorId}`);
  };

  const onCommentPress = useCallback(() => {
    onClose();
    setCommentParam({ type: parent.type, id: parent.id });
    setOpen(true);
  }, [onClose, setCommentParam, setOpen, parent]);

  const onScriptPress = () => {
    onDialogOpen();
  };

  return (
    <>
      <Drawer
        classes={{ paper: classes.root }}
        anchor="bottom"
        open={open}
        onClose={onClose}
      >
        <div role="presentation" onClick={onClose} onKeyDown={onClose}>
          <List>
            <ListItem button onClick={onLikePress}>
              <ListItemIcon>
                {isLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </ListItemIcon>
              <ListItemText
                primary="좋아요"
                primaryTypographyProps={{ className: classes.text }}
              />
            </ListItem>
            <ListItem button onClick={onScriptPress}>
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText
                primary="스크립트 보기"
                primaryTypographyProps={{ className: classes.text }}
              />
            </ListItem>
            <ListItem button onClick={onAuthorPress}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText
                primary="작가 페이지 가기"
                primaryTypographyProps={{ className: classes.text }}
              />
            </ListItem>
            <ListItem button onClick={onCommentPress}>
              <ListItemIcon>
                <MessageIcon />
              </ListItemIcon>
              <ListItemText
                primary="댓글 보기"
                primaryTypographyProps={{ className: classes.text }}
              />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <Dialog
        title="스크립트"
        positiveButtonText="확인"
        open={isDialogOpen}
        onClose={onDialogClose}
        hideNegativeButton
        onPositive={onDialogClose}
        classes={{ paper: classes.scriptDialog }}
        maxWidth="lg"
      >
        <Typography className={classes.script}>
          {script || '스크립트 보기는 준비중입니다.'}
        </Typography>
      </Dialog>
      {renderLoginDialog()}
    </>
  );
};

export default React.memo(StoryActionSheet);
