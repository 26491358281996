import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@memoryfactory/mui-components';

import Dialog from '~/components/Dialog';
import useUser from './useUser';
import useDisclose from './useDisclose';

function useLoginRequiredDialog(onAction?: () => void) {
  const user = useUser({});
  const { isOpen, onOpen, onClose } = useDisclose();
  const history = useHistory();

  const onSubmit = useCallback(() => {
    if (user) {
      onAction?.();
    } else {
      onOpen();
    }
  }, [onOpen, onAction, user]);

  const renderDialog = useCallback(() => {
    if (user) {
      return null;
    }
    return (
      <Dialog
        title="로그인"
        positiveButtonText="로그인"
        open={isOpen}
        onClose={onClose}
        onNegative={onClose}
        onPositive={() => {
          onClose();
          history.push('/accounts/login');
        }}
      >
        <Typography>로그인이 필요합니다</Typography>
      </Dialog>
    );
  }, [user, isOpen, onClose, history]);

  return { renderDialog, onSubmit };
}

export default useLoginRequiredDialog;
