/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TErrorResponse } from '~/utils/client';
import { ICommentListItem } from '~/features/profiles/interfaces';
import { createAuthorComment } from '../profiles/featchers';

import {
  createStoryCollectionComment,
  createStoryComment,
  createStorySeriesComment,
  storyCollectionLikeMutation,
  storyCollectionUnlikeMutation,
  storyLikeMutation,
  storySeriesLikeMutation,
  storySeriesUnlikeMutation,
  storyUnlikeMutation,
} from './fetchers';

export function useStoryCollectionLike(liked: boolean) {
  const queryClient = useQueryClient();
  const mutation = liked
    ? storyCollectionUnlikeMutation
    : storyCollectionLikeMutation;
  return useMutation(mutation, {
    onSuccess: () => {
      queryClient.invalidateQueries('storyCollection');
    },
  });
}

export function useStorySeriesLike(liked: boolean) {
  const queryClient = useQueryClient();
  const mutation = liked ? storySeriesUnlikeMutation : storySeriesLikeMutation;
  return useMutation(mutation, {
    onSuccess: () => {
      queryClient.invalidateQueries('storySeries');
    },
  });
}

export function useStoryLike(liked: boolean) {
  const queryClient = useQueryClient();
  const mutation = liked ? storyUnlikeMutation : storyLikeMutation;
  return useMutation(mutation, {
    onSuccess: () => {
      queryClient.invalidateQueries('storyCollection');
      queryClient.invalidateQueries('storySeries');
    },
  });
}

export function useContentCommentMutation(type: TCommentType, id: number) {
  const queryClient = useQueryClient();
  const mutation =
    type === 'story'
      ? createStoryComment
      : type === 'storySeries'
      ? createStorySeriesComment
      : type === 'author'
      ? createAuthorComment
      : createStoryCollectionComment;
  return useMutation<
    AxiosResponse<ICommentListItem>,
    TErrorResponse,
    ICommentData
  >(mutation, {
    onSuccess: () => {
      queryClient.invalidateQueries([`${type}CommentList`, id]);
    },
  });
}
