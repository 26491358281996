/* eslint-disable import/prefer-default-export */
import { useLocalStorage } from '@memoryfactory/common';
import { useQuery } from 'react-query';
import { IUserResponse } from './interfaces';

export function useUserQuery() {
  const [savedUser] = useLocalStorage<IUserResponse | null>('user', null);
  return useQuery(['user'], () => {
    if (savedUser) return savedUser;
    return null;
  });
}
