import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ShareIcon from '@material-ui/icons/Share';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LineIcon,
  LineShareButton,
} from 'react-share';
import { FlexRow, Typography, KakaoLink } from '@memoryfactory/mui-components';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { KAKAO_API_KEY } from '~/lib/constants';
import KakaoIcon from '../KakaoIcon';

type TProps = {
  open: boolean;
  onClose: () => void;
  url: string;
  cover: string;
  quote?: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  content: {
    minWidth: 360,
  },
  actions: {
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  shareIcon: {
    marginRight: theme.spacing(2),
    fontSize: 24,
  },
  kakaoButton: {
    padding: 0,
  },
  linkButton: {
    padding: 0,
    width: 48,
    height: 48,
    backgroundColor: theme.palette.primary.main,
  },
  linkIcon: {
    color: 'white',
    fontSize: 36,
  },
}));

const ShareDialog: React.FunctionComponent<TProps> = ({
  open,
  onClose,
  url,
  cover,
  quote = '나디오에서 당신의 주파수에 맞는 이야기들에 귀기울여 보세요.',
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.root }}>
      <DialogContent classes={{ root: classes.content }}>
        <FlexRow justifyContent="space-between">
          <FlexRow>
            <ShareIcon className={classes.shareIcon} />
            <Typography variant="subtitle1">공유하기</Typography>
          </FlexRow>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </FlexRow>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <FacebookShareButton url={url} quote={quote}>
          <FacebookIcon size={48} round />
        </FacebookShareButton>
        <TwitterShareButton url={url} title={quote}>
          <TwitterIcon size={48} round />
        </TwitterShareButton>
        <LineShareButton url={url} title={quote}>
          <LineIcon size={48} round />
        </LineShareButton>
        <KakaoLink
          apiKey={KAKAO_API_KEY || ''}
          settings={{
            objectType: 'feed',
            content: {
              imageUrl: cover,
              title: '',
              description: quote,
              link: {
                webUrl: url,
                mobileWebUrl: url,
              },
            },
          }}
        >
          <Button
            className={classes.kakaoButton}
            style={{ marginLeft: 0, marginRight: -8 }}
          >
            <KakaoIcon round />
          </Button>
        </KakaoLink>
        <CopyToClipboard text={url}>
          <IconButton className={classes.linkButton}>
            <LinkIcon className={classes.linkIcon} />
          </IconButton>
        </CopyToClipboard>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog;
