import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useUserQuery } from '~/features/accounts/queries';

type Props = {
  redirectTo?: string;
  redirectIfFound?: boolean;
};

export default function useUser({ redirectTo, redirectIfFound }: Props) {
  const history = useHistory();
  const userQuery = useUserQuery();
  const user = userQuery?.data;
  const finished = userQuery.isSuccess;
  const hasUser = Boolean(user);

  useEffect(() => {
    if (!redirectTo || !finished) return;
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !hasUser) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && hasUser)
    ) {
      history.replace(redirectTo);
    }
  }, [history, redirectTo, redirectIfFound, finished, hasUser]);

  return userQuery.error ? null : user;
}
