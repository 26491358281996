import { useState, useCallback } from 'react';

function useDisclose() {
  const [isOpen, setOpen] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const toggleOpen = useCallback(() => {
    setOpen((v) => !v);
  }, []);

  return {
    isOpen,
    onClose,
    onOpen,
    toggleOpen,
    setOpen,
  };
}

export default useDisclose;
