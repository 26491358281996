import {
  colors,
  Dialog as MuiDialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { FlexRow, Typography } from '@memoryfactory/mui-components';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  closeButton: {
    color: colors.grey[600],
    cursor: 'pointer',
    transition: '0.2s',

    '&:hover': {
      color: colors.red[600],
    },
  },
}));

interface IBaseDialogProps {
  open: boolean;
  title?: string;
  positiveButtonText?: string;
  negativeButtonText?: string;
  hidePositiveButton?: boolean;
  hideNegativeButton?: boolean;
  hideCloseButton?: boolean;
  cancelable?: boolean;
  onPositive?: () => void;
  onNegative?: () => void;
  onClose: () => void;
}

type Props = IBaseDialogProps &
  Omit<DialogProps, keyof IBaseDialogProps | 'onSubmit'>;

function Dialog({
  open,
  title,
  positiveButtonText = '확인',
  negativeButtonText = '취소',
  hidePositiveButton = false,
  hideNegativeButton = false,
  hideCloseButton = false,
  cancelable = true,
  onPositive,
  onNegative,
  onClose,
  ...others
}: Props) {
  const { maxWidth = 'sm', children } = others;
  const classes = useStyles();

  return (
    <MuiDialog
      {...others}
      open={open}
      maxWidth={maxWidth}
      onClose={() => {
        if (cancelable) {
          onClose();
        }
      }}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <FlexRow justifyContent="space-between">
          <Typography variant="h6">{title}</Typography>

          {hideCloseButton || (
            <IconButton size="small" onClick={onClose}>
              <CloseIcon className={classes.closeButton} />
            </IconButton>
          )}
        </FlexRow>
      </DialogTitle>

      <DialogContent>{children}</DialogContent>

      {!(hideNegativeButton && hidePositiveButton) && (
        <DialogActions>
          {hideNegativeButton || (
            <Button onClick={onNegative}>
              <Typography variant="button">{negativeButtonText}</Typography>
            </Button>
          )}
          {hidePositiveButton || (
            <Button onClick={onPositive}>
              <Typography variant="button" color="primary">
                {positiveButtonText}
              </Typography>
            </Button>
          )}
        </DialogActions>
      )}
    </MuiDialog>
  );
}

export default Dialog;
