import qs from 'query-string';

import restClient from '~/utils/client';
import { ICommentListParam } from '../works/interfaces';
import {
  IAuthor,
  ISubscribedAuthorListItem,
  IConfirmPhoneCodeData,
  ICreateUserProfileData,
  IProfile,
  IRequestPhoneCodeData,
  ISubscirbeAuthorData,
  IUpdateProfileData,
  ICommentListItem,
  ISubscribeUserListParam,
  ISubscribedUserListItem,
  ISubscribedAuthorListParam,
} from './interfaces';

const PREFIX = '/profiles';

export function getAuthor(id: number) {
  return restClient
    .get<IAuthor>(`${PREFIX}/authors/${id}/`)
    .then((res) => res?.data);
}

export function subscribeAuthor(data: ISubscirbeAuthorData) {
  return restClient.post(`${PREFIX}/me/authors/`, data);
}

export function unsubscribeAuthor(id: number) {
  return restClient.delete(`${PREFIX}/me/authors/${id}/`);
}

export function requestPhoneCode(data: IRequestPhoneCodeData) {
  return restClient.post(`${PREFIX}/verifications/phone/generate-code/`, data);
}

export function confirmPhoneCode(data: IConfirmPhoneCodeData) {
  return restClient.post(`${PREFIX}/verifications/phone/confirm-code/`, data);
}

export function createUserProfile(data: ICreateUserProfileData) {
  return restClient.post(`${PREFIX}/`, data);
}

export function getMyProfile() {
  return restClient.get<IProfile>(`${PREFIX}/me/`).then((res) => res?.data);
}

export function getMySubscribedAuthorList(params?: ISubscribedAuthorListParam) {
  return restClient
    .get<IListResponse<ISubscribedAuthorListItem>>(
      `${PREFIX}/me/authors/?${qs.stringify({ ...params })}`,
    )
    .then((res) => res?.data);
}

export function updateUserProfile(data: IUpdateProfileData) {
  return restClient.patch(`${PREFIX}/me/`, data);
}

export function getAuthorCommentList(id: number, params?: ICommentListParam) {
  return restClient
    .get<IListResponse<ICommentListItem>>(
      `${PREFIX}/authors/${id}/comments/?${qs.stringify({ ...params })}`,
    )
    .then((res) => res?.data);
}

export function createAuthorComment(values: ICommentData) {
  return restClient.post(`${PREFIX}/authors/${values.id}/comments/`, {
    author: values.id,
    ...values.data,
  });
}

export function getSubscribedUserList(
  id: number,
  params?: ISubscribeUserListParam,
) {
  return restClient
    .get<IListResponse<ISubscribedUserListItem>>(
      `${PREFIX}/authors/${id}/subscribers/?${qs.stringify({ ...params })}`,
    )
    .then((res) => res?.data);
}
