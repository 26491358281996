import { keysToCamel } from '@memoryfactory/common';
import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../lib/constants';

const restClient = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

restClient.interceptors.response.use(
  (res: AxiosResponse<Record<string, any>>) => {
    if (res.data) {
      res.data = keysToCamel(res.data);
    }
    return res;
  },
  async (err) => {
    if (err?.response?.status === 401) {
      window?.localStorage.removeItem('user');
    }
    return Promise.reject(err?.response || err);
  },
);

export type TErrorResponse = AxiosResponse<Record<any, any>>;

export default restClient;
