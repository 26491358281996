import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import flatten from 'lodash/flatten';
import {
  FlexRow,
  Typography,
  View,
  TextInput,
} from '@memoryfactory/mui-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Modal,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import Close from '@material-ui/icons/Close';
import useUser from '~/hooks/useUser';
import CommentRow from '~/components/CommentRow';
import { useContentCommentMutation } from '~/features/works/mutations';
import { useContentCommentListQuery } from '~/features/works/queries';
import { commentModalState, commentParamState } from '~/features/works/recoil';

type TFormValues = {
  text: string;
};

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '90%',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[5],
    '& #commentList': {
      height: '75%',
      overflowY: 'scroll',
    },
  },
  input: {
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: 'white',
    fontSize: 14,
    paddingLeft: 8,
    borderRadius: 4,
    marginRight: 8,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: 0,
  },
}));

const CommentListModal: React.FunctionComponent = () => {
  const classes = useStyles();

  const observeTarget = useRef<HTMLDivElement | null>(null);
  const commentListRef = useRef<HTMLDivElement | null>(null);
  const [{ type, id }] = useRecoilState(commentParamState);
  const [open, setOpen] = useRecoilState(commentModalState);
  const user = useUser({});

  const commentListQuery = useContentCommentListQuery(type, id);
  const commentMutation = useContentCommentMutation(type, id);

  const commentList = useMemo(
    () => commentListQuery.data?.pages.map((page) => page.results),
    [commentListQuery],
  );

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting, errors: formErrors },
  } = useForm();

  const submitAble = !!(watch('text') as string)?.trim();

  const onSubmit: SubmitHandler<TFormValues> = useCallback(
    (data) => {
      if (!isSubmitting && !commentMutation.isLoading) {
        commentMutation.mutate(
          { id, data },
          {
            onSuccess: () => {
              reset({ text: '' });
              commentListRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
            },
          },
        );
      }
    },
    [commentMutation, id, reset, isSubmitting],
  );

  const getNextPage = useCallback(() => {
    if (commentListQuery.hasNextPage) {
      commentListQuery.fetchNextPage();
    }
  }, [commentListQuery]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onIntersect: IntersectionObserverCallback = ([entry]) => {
    if (entry.isIntersecting) {
      getNextPage();
    }
  };

  useEffect(() => {
    let observer: IntersectionObserver;
    if (observeTarget.current) {
      observer = new IntersectionObserver(onIntersect, {
        root: globalThis.document.getElementById('commentList'),
        threshold: 0.5,
      });
      observer.observe(observeTarget.current);
    }
    return () => {
      observer?.disconnect();
    };
  }, [onIntersect]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <View className={classes.modal}>
        {commentMutation.isLoading && <LinearProgress />}
        <FlexRow mb={4} py={2} justifyContent="center" position="relative">
          <Typography variant="h6" align="center">
            한마디
          </Typography>
          <IconButton
            onClick={() => setOpen(false)}
            className={classes.closeButton}
          >
            <Close />
          </IconButton>
        </FlexRow>
        <View id="commentList" px={2} pb={4} ref={commentListRef}>
          {commentList && (
            <>
              {flatten(commentList).map((item) => (
                <CommentRow
                  key={item.id}
                  type={type}
                  contentId={id}
                  user={user}
                  item={item}
                />
              ))}
            </>
          )}
          {commentListQuery.hasNextPage && (
            <div ref={observeTarget} style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          )}
          {flatten(commentList).length === 0 && (
            <View alignSelf="center">
              <Typography>첫 댓글의 주인공이 되어주세요!</Typography>
            </View>
          )}
          {!user && (
            <View alignSelf="center" alignItems="center" my={2}>
              <View mb={2}>
                <Typography>댓글 작성은 로그인이 필요해요:)</Typography>
              </View>
              <Link to="/accounts/login">
                <Button variant="contained" color="primary">
                  로그인하기
                </Button>
              </Link>
            </View>
          )}
        </View>
        {user && (
          <View px={1} py={2} position="absolute" bottom={8} right={0} left={0}>
            <TextInput
              name="text"
              errors={formErrors}
              maxLength={100}
              register={register({
                required: '답글을 입력해주세요',
              })}
              placeholder="답글을 남겨주세요."
              variant="standard"
              InputProps={{
                classes: { input: classes.input },
                endAdornment: (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    style={{ fontSize: 12, borderRadius: 8 }}
                    disabled={!submitAble || isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                  >
                    작성
                  </Button>
                ),
              }}
            />
          </View>
        )}
      </View>
    </Modal>
  );
};

export default CommentListModal;
