import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { IconButton } from '@material-ui/core';
import { FlexRow, Typography } from '@memoryfactory/mui-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useDisclose from '~/hooks/useDisclose';

import {
  playerTrackListState,
  playListModalState,
  currentTrackState,
  playerPlayState,
  playerModalState,
} from '~/features/works/recoil';

import TrackRowItem from '../TrackRowItem';
import StoryActionSheet from '../StoryActionSheet';
import CommentListModal from './CommentListModal';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'flex-end',
    padding: 16,
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
  },
  paper: {
    margin: 0,
    maxWidth: '100%',
    width: '100%',
    maxHeight: '70%',
    backgroundColor: 'white',
  },
  content: {
    padding: `${theme.spacing(1)}px 0`,
  },
  closeIcon: {
    color: theme.palette.grey['500'],
  },
}));

function PlayListModal() {
  const classes = useStyles();
  const [open, setOpen] = useRecoilState(playListModalState);
  const playList = useRecoilValue(playerTrackListState);
  const [currentTrack, setCurrentTrack] = useRecoilState(currentTrackState);
  const setPlaying = useSetRecoilState(playerPlayState);
  const setPlayerModal = useSetRecoilState(playerModalState);

  const [currentRow, setCurrentRow] = useState<ITrack | null>(null);

  const {
    isOpen: sheetOpen,
    onClose: onSheetClose,
    onOpen: onSheetOpen,
  } = useDisclose();

  const onTrackClick = (track: ITrack) => {
    const index = playList.findIndex((o) => o.id === track.id);
    if (index !== -1) {
      setCurrentTrack({ index, track });
      setPlaying(true);
    }
    setPlayerModal(true);
  };
  const onMoreClick = (item: ITrack) => {
    setCurrentRow(item);
    onSheetOpen();
  };

  return (
    <Dialog
      open={open}
      classes={{ container: classes.root, paper: classes.paper }}
    >
      <DialogTitle style={{ padding: '4px 4px 4px 16px' }}>
        <FlexRow justifyContent="space-between">
          <Typography variant="h6">다음 나디오</Typography>
          <IconButton onClick={() => setOpen(false)} style={{ padding: 0 }}>
            <ExpandMoreIcon fontSize="large" className={classes.closeIcon} />
          </IconButton>
        </FlexRow>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {playList.map((track) => {
          const isPlaying = currentTrack?.track.id === track.id;
          return (
            <TrackRowItem
              key={track.id}
              item={track}
              isPlaying={isPlaying}
              onClick={onTrackClick}
              onMoreClick={onMoreClick}
            />
          );
        })}
      </DialogContent>
      {currentRow && (
        <StoryActionSheet
          open={sheetOpen}
          onClose={onSheetClose}
          isLiked={currentRow.isLiked}
          storyId={currentRow.id}
          parent={{ type: 'storySeries', id: currentRow.seriesId }}
          authorId={currentRow.author}
          script={currentRow.script}
        />
      )}
      <CommentListModal />
    </Dialog>
  );
}

export default PlayListModal;
