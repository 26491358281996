import React from 'react';
import { IconButton, Theme } from '@material-ui/core';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import {
  FlexRow,
  Image,
  View,
  Typography,
} from '@memoryfactory/mui-components';
import { makeStyles } from '@material-ui/core/styles';

type TProps = {
  item: ITrack;
  isPlaying?: boolean;
  onOpen?: () => void;
  onClick: (track: ITrack) => void;
  onMoreClick?: (track: ITrack) => void;
};

type TStyleProps = {
  isPlaying?: boolean;
};

const useStyles = makeStyles<Theme, TStyleProps>((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    backgroundColor: ({ isPlaying }) =>
      isPlaying ? theme.palette.grey['200'] : 'transparent',
  },
  cover: {
    marginRight: theme.spacing(2),
  },
  moreButton: {
    padding: 0,
    '& .icon': {
      color: theme.palette.grey['500'],
    },
  },
}));

const TrackRowItem: React.FunctionComponent<TProps> = ({
  item,
  isPlaying,
  onClick,
  onMoreClick,
}) => {
  const classes = useStyles({ isPlaying });
  return (
    <FlexRow
      pointer
      justifyContent="space-between"
      alignItems="stretch"
      mb={4}
      className={classes.root}
    >
      <FlexRow
        flex={1}
        height={48}
        onClick={onClick ? () => onClick(item) : undefined}
      >
        <Image
          className={classes.cover}
          src={item.artwork}
          alt={item.title || '커버'}
          width={48}
          height="100%"
        />
        <div>
          <Typography variant="body2">{item.title}</Typography>
          <Typography variant="caption" color="textSecondary">
            {item.seriesName}
          </Typography>
        </div>
      </FlexRow>
      <View justifyContent="space-between" alignItems="center">
        <Typography variant="caption" color="textSecondary" gutterBottom>
          {new Date((item.duration || 0) * 1000).toISOString().substr(14, 5)}
        </Typography>
        <IconButton
          onClick={onMoreClick ? () => onMoreClick(item) : undefined}
          className={classes.moreButton}
        >
          <MoreHoriz className="icon" />
        </IconButton>
      </View>
    </FlexRow>
  );
};

export default TrackRowItem;
